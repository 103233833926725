.testimonios-container{
    min-height: 15rem;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.testimonio-card{
    position: relative;
    width: 18em;
    text-align: left;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    background-color: #F9F9F9;
    border-color: transparent;
    margin-top: 2rem;
    margin-bottom: 2rem;
    min-height: 18rem;
}

.card-icon{
    justify-self: left;
    margin-left: 0.9rem;
}	

.testimonio-card-content{
    padding-top: 1.5rem;
    justify-content: space-between;
}

.testimonio-card-body{
    padding-left: 1.3rem;
    padding-right: 1.3rem;
}

.testimonio-card-text{
    font-size: 1.1rem;
    font-style: italic;
    color: #000;
}

.testimonio-card-author{
    font-size: 0.rem;
    color: #000;
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23696969'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23696969'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

@media (max-width: 425px) {
    .testimonio-card{
        min-width: 18rem;
    }
    .carousel-control-next-icon {
        margin-right: -1.5rem;
    }
    .carousel-control-prev-icon {
        margin-left: -1.5rem;
    }
}

@media (max-width: 425px) {
    .testimonio-card{
        min-width: 16rem;
    }
}

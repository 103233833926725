.App {
  font-family: "Titillium Web";
  min-height: 100vh;
  overflow-y: auto;
}

.page{
  margin-top: 4em;
  min-height: 84vh;
}

.titulos-secciones {
  text-align: center;
  font-size: 36px;
  font-weight: bold;
  color: #3f3f3f;
  margin: 1rem 0;
}

.container {
  flex-grow: 1;
  overflow-y: auto;
}

.container-fluid{
  text-align: center;
}

.planes-container {
    min-height: 20rem;
}	

.plan-cards-container{
    display: flex;
    justify-content: center;
    gap: 3rem;
    align-items: center;
    width: 100%;
}

.plan-button{
    background-color: #d5ddee74;
    color: #7C91BE;
    border: 1px solid transparent;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: all 0.3s;
    width: 100%;
    font-size: 16px;
    margin-top: 1rem;
}

.plan-card{
    color: #000;
    padding: 0.5rem;
    border-radius: 1.5rem;
    border-color: transparent;
    text-align: start;
    transition: all 0.3s;
    width: 22rem;
    height: auto;
}

.plan-card:hover{
    background-color: #7C91BE;
    color: #FFFFFF;
    transform: translateY(-25px);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.plan-card-title{
    font-size: 1.5rem;
    font-weight: 600;
}	

#plan-card-description{
    font-size: 1rem;
}	

.plan-icon{
    background-image: url('../../img/check-circle-1.png');
    width: 1.4rem;
    height: 1.4rem;
    background-size: contain;
    margin-bottom: 0.8em;
    margin-left: 0.7em;
}

.plan-card:hover .plan-icon{
    background-image: url('../../img/check-circle-2.png');
}

.item-text{
    font-size: 1rem;
    margin-left: -0.2rem;
}

.plan-card:hover .item-text #plan-card-description{
    color: #FFFFFF;
}

.plan-card:hover #plan-card-description{
    color: #FFFFFF;
}

.plan-card:hover .plan-button{
    background-color: #f8faffd0;
    color: #7C91BE;
}

.plan-button:hover{
    border: 1px solid transparent;
    background-color: #ffffff;
}


#plan-card-description{
    font-style: normal;
    font-weight: 300;
}

.planes-subtitulo{
    margin-bottom: 2rem;
}

@media (max-width: 1200px) {
    .plan-cards-container {
        flex-wrap: wrap;
        width: 100%;
        gap: 1.5rem;
    }
}

@media (max-width: 1024px) {
    .plan-card {
        width: 20rem;
    }	
}
.faq-container-fluid {
  padding: 0;
}

#clase {
  background-color: #f4f4f4;
  width: 80%;
  padding: 38px 20px 43px 20px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
  justify-items: center;
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
  border-radius: 2rem;  
}

#tituloclase,
#parrafoclase,
#botonclase {
  text-align: center;
  max-width: 90%; /* Ancho máximo para los textos y el botón */
}

#tituloclase {
  color: var(--Gray-900, #101828);
  font-family: "Titillium Web";
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: -0.72px;
  max-width: 800px;
  margin-bottom: 20px;
}

#parrafoclase {
  color: var(--Gray-500, #667085);
  font-family: "Titillium Web";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  max-width: 800px;
}

#contenedorBotones {
  display: flex;
  justify-content: space-between; /* Esto añade espacio entre los botones */
  width: 100%;
}

#botonclase {
  color: #ffff;
  font-family: "Titillium Web";
  font-size: 16px;
  font-weight: 600;
  box-sizing: border-box; /* Incluye el padding y el borde en el ancho total */
  width: 10rem;
  max-width: 100%;
  padding: 12px 0px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #5d74a7;
  background: #7c91be;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  align-self: center; /* Alinea el botón al centro vertical en todas las resoluciones */
  margin-left: 1rem;
}
#botonagendar {
  color: #344054;
  font-family: "Titillium Web";
  font-size: 16px;
  font-weight: 600;
  box-sizing: border-box; /* Incluye el padding y el borde en el ancho total */
  width: 10rem;
  max-width: 100%;
  padding: 12px 0px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #bdc0c7;
  background: #ffffff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  align-self: center; /* Alinea el botón al centro vertical en todas las resoluciones */
}
#botonagendar:hover {
  background: #f1f5ff;
}

@media screen and (max-width: 417px) {
    #botonclase {
        width: 100%;
        margin-left: 0;
    }
    #botonagendar {
        width: 100%;
        margin-left: 0;
        margin-bottom: 1rem;
    }
    #clase{
        width: 100%;
        margin: 0;
        border-radius: 0;
    }
}

@media screen and (max-width: 630px) {
    #clase{
        width: 100%;
        margin: 0;
        border-radius: 0;
    }
}
.container{
    text-align: center;
}

.item-container{
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
}

.item{
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    padding: 40px;
    margin: 10px;
    transition: all 0.3s ease;
}

.item-icon{
    max-width: 90px;
    max-height: 95px;
    margin-top: 1rem;
}

@media (min-width: 1024px){
    .item{
        width: 400px;
        max-width: 400px;
        min-height: 350px;
        max-height: 350px;
    }
    .item-container{
        flex-wrap: wrap;
    }
}

@media (max-width: 1024px){
    .item-container{
        flex-wrap: wrap;
    }
}

@media (min-width: 768px) {
    .item {
      width: auto; 
    }
}

.circle {
    width: 150px;
    height: 150px;
    background: #DFE0F1;
    border-radius: 43%;
    border-color: #F9F5FF;
    border-width: 10px;
    border-style: solid; 
    margin: 0 auto;
}

.item-body{
    margin-top: 1.5rem;
}

@media (min-width: 576px) {
    .item {
      width: calc(50% - 20px);
    }
}

@media (min-width: 1024px){ 
    .item{
        width: calc(33.33% - 20px);
        min-height: 350px;
        max-height: 350px;
    }
    .item-container{
        flex-wrap: wrap;
    }
}
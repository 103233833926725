#clase {
  background-color: #f4f4f4;
  width: 100%;
  padding: 38px 20px 43px 20px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
  justify-items: center;
}

#tituloclase,
#parrafoclase,
#botonclase {
  text-align: center;
  max-width: 90%; /* Ancho máximo para los textos y el botón */
}

#tituloclase {
  color: var(--Gray-900, #101828);
  font-family: "Titillium Web";
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: -0.72px;
  max-width: 800px;
  margin-bottom: 20px;
}

#parrafoclase {
  color: var(--Gray-500, #667085);
  font-family: "Titillium Web";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  max-width: 800px;
}

#botonclase {
  color: #FFFF;
  font-family: "Titillium Web";
  font-size: 16px;
  box-sizing: border-box; /* Incluye el padding y el borde en el ancho total */
  width: 280px;
  max-width: 100%;
  padding: 12px 0px ;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #7c91be;
  background: #7c91be;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  align-self: center; /* Alinea el botón al centro vertical en todas las resoluciones */
}


@media (max-width: 767px) {
  #botonclase {
    
    max-width: 280px; /* Ancho máximo del botón */
  }
}

#botonclase:hover {
  background: #6b7f9f;
  border: 1px solid #6b7f9f;
}
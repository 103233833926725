.navbar {
  background-color: white;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  align-items: center;
  font-size: 17px;
}

.navbar-nav {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 992px) {
  .navbar-collapse {
    display: flex;
    justify-content: center;
  }
}

.navbar-contactar{
  background-color:#e6e7f7;
  color: black;
  display: flex;
  justify-content: center;
  font-weight: bold;
  border-radius: 2px;
  border-color: black;
  min-width: 12rem;
}

.navbar-contactar:hover{
  background-color: #DFE0F1;
  color: black;
  border-color: black;
}

.navbar-contactar.clicked {
  background-color: #DFE0F1;
  color: black;
  border-color: black;
}